




























import {Component, Prop, Vue} from 'vue-property-decorator';
import Severity, {getSeveritySelectItems} from "@/types/Severity";
import {OutputRuleDTO, UpdateRuleDTO} from "@/api";
import Loading from "@/components/Loading.vue";
@Component({
  components: {Loading}
})
export default class RulesEdit extends Vue {
  @Prop(Number) readonly ruleId!: number;

  public valid = true;

  public messageTypes: string[] = ['STATE', 'EVENT'];
  public severities = getSeveritySelectItems()

  public name: string = '';
  public messageType: string = this.messageTypes[0];
  public severity: string = Severity.INFO;
  public note: string|null = '';
  public jsonRule: string = '';

  public rule: OutputRuleDTO|null = null;

  public async mounted() {
    this.rule = await this.$store.dispatch('rules/get', { ruleId: this.ruleId })

    this.reset();
  }

  public reset() {
    this.$validator.reset();

    if (this.rule) {
      this.name = this.rule.name;
      this.messageType = this.rule.message_type!;
      this.severity = this.rule.severity;
      this.note = this.rule.note ?? null;
      this.jsonRule = JSON.stringify(this.rule.rule_data, null, 4);
    }
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const dto = {
        name: this.name,
        rule_data: JSON.parse(this.jsonRule),
        note: this.note,
        severity: this.severity,
        message_type: this.messageType
      } as UpdateRuleDTO;

      await this.$store.dispatch('rules/update', { ruleId: this.ruleId, data: dto });

      await this.$router.push('/main/admin/rules/all');
    }
  }

  public validJsonRule(x) {
    try {
      JSON.parse(x);
    } catch (e) {
      return 'Invalid JSON data';
    }
    return true;
  }
}
